@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300&display=swap");

.wrapper {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  background: #edf1f5;
  .text_group {
    .text_404 {
      font-family: "Comic Neue", cursive;
      font-size: 10em;
      box-sizing: border-box;
      color: #363636;
    }
    .text_lost {
      font-family: "Comic Neue", cursive;
      font-size: 2em;
      line-height: 50px;
      box-sizing: border-box;
      color: #565656;
    }
  }
  .window_group {
    .window_404 {
      width: 200px;
      height: 350px;
      border-radius: 100px;
      box-shadow: -3px -3px 0px 5px rgba(212, 212, 212, 1),
        5px 5px 0px 2px rgba(255, 255, 255, 1);
      background: linear-gradient(
        310deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 107, 1) 0%,
        rgba(0, 0, 0, 1) 80%
      );
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      .stars {
        width: 400px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        animation: flyby 30s linear infinite;
        .star {
          border-radius: 50%;
          background: #ffffff;
          position: absolute;
          @for $i from 1 through 100 {
            $size: random(3) + px;
           
            &:nth-of-type(100) {
              width: $size;
              height: $size;
              left: random(400) + px;
              top: random(350) + px;
              animation: twinkle(100) random(8) + 5s linear infinite;
              animation-delay: random(15) + 3s;
              &:before {
                content: "";
                width: $size;
                height: $size;
                position: absolute;
                top: 0;
                left: 0;
                background: #fff;
                filter: blur(1px);
              }
            }
          }
        }
      }
    }
  }
}

@for $k from 1 through 100 {
  @keyframes twinkle#{$k} {
    0% {
      transform: scale(1, 1);
    }
    10% {
      transform: scale(0.3, 0.3);
    }
    20% {
      transform: scale(1, 1);
    }
    30% {
      transform: scale(0.5, 0.5);
    }
    40% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

@keyframes flyby {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}
@media only screen and (min-width: 1080px) {
  .wrapper {
    .text_group {
      flex: 0 0 30%;
      margin-left: 25%;
      align-items: flex-end;
    }
    .window_group {
      flex: 1 0 40%;
      margin-top: 0;
      margin-left: 5%;
    }
  }
}
@media only screen and (max-width: 1079px) {
  .wrapper {
    .text_group {
      flex: 0 0 100%;
      margin: 0;
      align-items: center;
      .text_lost {
        width: 100%;
        padding: 0 22px;
        font-size: 1.7em;
        line-height: 35px;
      }
    }
    .window_group {
      flex: 0 0 100%;
      .window_404 {
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
      }
    }
  }
}