

.search input {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
  }

  .search input[type=text]:focus {
    outline-color: #14B8A6;
    outline-style:solid;
    outline-width: 2px;
  }
  .search input[type=text]:hover {
    border-color: #14B8A6;
  }
  .search input[type="text"]:disabled {
    background: #dddddd;
    border-color: #dddddd;
    border-style: hidden;
  }


  .locate {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    z-index: 10;
  }
  .locate img {
    width: 30px;
    cursor: pointer;
  }
  .add {
    
    right: 40rem;
    bottom: 19rem;
    background: none;
    border: none;
  }
  .add img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  /* customize datepicker default styles */
  .react-datepicker {
    display: block;
    width: 100%;
  }
  .react-datepicker__month-container {
    display: block;
    width: 100%; 
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
.react-datepicker__input-time-container {
	margin: 0px !important;
}
.react-datepicker-time__caption {
	display: none !important;
}
.react-datepicker-time__input-container,
.react-datepicker-time__input {
	width: 100%;
	margin: 0 !important;
}